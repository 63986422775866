<template>
  <div id="footer" class="footer-two footer-bg">
    <div class="container-fluid bb-solid-1">
      <div class="container pb35">
        <div class="row">
          <!-- footer about start -->
          <div class="col-md-5 col-sm-12 col-xs-12">
<!--            <h6 class="foot-name"><img :src="getWebsiteInfo.logo" alt="logo"> {{getWebsiteInfo.name}}</h6>-->
            <h6 class="foot-name"><img :src="getWebsiteInfo.logo" alt="logo"> 线上线下招商运营平台</h6>
            <p class="color-light">{{getWebsiteInfo.description}} </p>
<!--            <p class="color-light" style="cursor: pointer" @click="toHt">-->
<!--              {{getWebsiteInfo.name}}-->
<!--              <span style="color: #ff4530">点击进入后台管理</span>-->
<!--              <img style="width: 20px;height: 20px;margin-top: 5px" src="https://railway-park.oss-cn-shanghai.aliyuncs.com/news/cover/2023/07/1690313111886.svg">-->
<!--            </p>-->
          </div>
          <!-- footer about end -->
          <!-- footer menu one start -->
          <div class="col-md-2 col-md-offset-1 col-sm-3 col-xs-12" style="cursor: pointer">
            <ul class="no-icon-list">
              <li ><a @click="to({})" v-if="!wx">首页</a></li>
<!--              <li v-for="(item, index) in getNavList.bottom" :key="index"><a href="#">{{item.navName}}</a></li>-->
              <li v-for="(item, index) in getNavList.top" :key="index">
                <a @click="to(item)" >{{item.navName}}</a>
              </li>
            </ul>
          </div>
          <!-- footer menu two end -->

          <!-- footer social icons start -->
          <div class="col-md-4 col-sm-5 col-xs-12 mobmt30">
            <p class="mb5">联系我们</p>
            <p>{{getAboutInfo.phone}}<br>
              {{getAboutInfo.province+getAboutInfo.city+getAboutInfo.county+getAboutInfo.address}}</p>
            <div class="social social-two">
              <img :src="getWebsiteInfo.qrcode" alt="" class="footqrcode">
              <img :src="'https://railway-park.oss-cn-shanghai.aliyuncs.com/about/2023/05/WechatIMG1142.jpeg'" alt="" class="footqrcode">
              <img :src="'https://railway-park.oss-cn-shanghai.aliyuncs.com/about/2023/05/WechatIMG1141.jpeg'" alt="" class="footqrcode">
            </div>
          </div>
          <!-- footer social icons end -->
        </div>
        <!-- row end -->
      </div>
    </div>
    <div class="container-fluid pt20">
      <div class="container">
        <div class="row">

          <!-- copyright start -->
          <div class="col-md-6 col-sm-6 col-xs-12 pull-left">
<!--            <p class="mobcenter">Copyright ©2023 <a href="#">临港产业园管委会</a>. 版权所有.</p>-->
            <p class="mobcenter">{{getWebsiteInfo.copyright}}</p>
          </div>
          <!-- copyright end -->

          <!-- footer bottom start -->
          <div class="col-md-6 col-sm-6 col-xs-12 pull-right">
            <p class="text-right mobcenter"> <a :href="'https://beian.miit.gov.cn'" target="_blank">{{getWebsiteInfo.recordNumber}}</a> </p>
          </div>
          <!-- footer bottom end -->

        </div>
        <!-- row end -->
      </div>
      <!-- container end -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Footer",
  data() {
    return {
      bottom: [],
      wx: false
    }
  },
  computed: {
    ...mapGetters(['getWebsiteInfo', 'getAboutInfo', 'getNavList']),
  },
  created() {
    // this.init()
    this.isWx()
  },
  methods: {
    isWx() {
      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.wx = true
          }
        });
      }
    },
    init() {
      this.getUcHomeNavigationList()
    },
    getUcHomeNavigationList() {
      this.$api.www.getUcHomeNavigationList({}).then(res=>{
        this.bottom = res.data.bottom
      })
    },
    to(item) {
      if (!this.wx) {
        if (item && item.navLink) {
          if (this.$route.path+"?navId="+this.$route.query.navId !== item.navLink+"?navId="+item.id) {
            this.$router.push(item.navLink+"?navId="+item.id)
          }
        } else {
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
        }
      } else {
        uni.navigateTo({
          url: '/pages/public'+item.navLink+'/list?navId='+item.id
        });
      }
    },
    toHt() {
      window.open('http://home.railway-park.com/')
    }
  }
}
</script>

<style scoped>
.footer-bg{
  padding-top: 50px;
  background-color: #323a45 !important;
}
.bb-solid-1{
  border-bottom: 1px solid #666;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.foot-name {
  color: #fff;
  font-family: 'guobiao';
  font-size: 18px;
  line-height: 40px;
}
.foot-name img {
  width: 40px;
  margin-top: -5px;
}
p {
  font-family: 'Open Sans', sans-serif !important;
  /*margin: 0 0 25px 0;*/
  font-size: 13px;
  line-height: 25px;
  color: #747474;
}
p strong {
  font-weight: 600;
}
.footqrcode {
  width: 80px;
  padding-right: 10px;
}

.footer-two a {
  color: #747474;
}

a {
  color: #111;
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
</style>